import React from 'react'

const LoadingComponent = () => {
    return (
        <div className="loading-container">
            <img src={'/logo192.png'} alt="Cargando..." className="loading-image" />
        </div>
    )
}

export default LoadingComponent