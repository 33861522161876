import {
    getAppointmentsService,
    createAppointmentService,
    addExamAppointmentService,
    addVaccineAppointmentService,
    addDewormingAppointmentService,
    getAppointmentsStatusService,
    editAppointmentService,
    getAppointmentsByIdService
} from "../services/appointments";

const getAppointments = async (data) => {
    try {
        const response = await getAppointmentsService(data);
        return response;
    } catch (error) {
        console.error("Error getting appointments:", error.message);
        throw error;
    }
};

const createAppointment = async (data) => {
    try {
        const response = await createAppointmentService(data);
        return response.data;
    } catch (error) {
        console.error("Error creating appointment:", error.message);
        throw error;
    }
};

const editAppointment = async (data) => {
    try {
        const response = await editAppointmentService(data);
        return response.data;
    } catch (error) {
        console.error("Error editing appointment:", error.message);
        throw error;
    }
};

const getAppointmentsById = async (data) => {
    try {
        const response = await getAppointmentsByIdService(data);
        return response.data;
    } catch (error) {
        console.error("Error getting appointments by id:", error.message);
        throw error;
    }
};

const addExamAppointment = async (data) => {
    try {
        const response = await addExamAppointmentService(data);
        return response.data;
    } catch (error) {
        console.error("Error adding exam appointment:", error.message);
        throw error;
    }
};

const addVaccineAppointment = async (data) => {
    try {
        const response = await addVaccineAppointmentService(data);
        return response.data;
    } catch (error) {
        console.error("Error adding vaccine appointment:", error.message);
        throw error;
    }
};

const addDewormingAppointment = async (data) => {
    try {
        const response = await addDewormingAppointmentService(data);
        return response.data;
    } catch (error) {
        console.error("Error adding deworming appointment:", error.message);
        throw error;
    }
};

const getAppointmentsStatus = async () => {
    try {
        const response = await getAppointmentsStatusService();
        return response.data;
    } catch (error) {
        console.error("Error getting appointments status:", error.message);
        throw error;
    }
};



export {
    getAppointments,
    createAppointment,
    addExamAppointment,
    addVaccineAppointment,
    addDewormingAppointment,
    getAppointmentsStatus,
    editAppointment,
    getAppointmentsById
};