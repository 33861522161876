import React from "react";

const Login = React.lazy(() => import("../pages/auth/Login"));
const SignUp = React.lazy(() => import("../pages/auth/SignUp"));
const ForgotPassword = React.lazy(() => import("../pages/auth/ForgotPassword"));

const Dashboard = React.lazy(() => import("../pages/views/dashboard/"));

const AddPet = React.lazy(() => import("../pages/views/pets/add"));
const ListPets = React.lazy(() => import("../pages/views/pets/list"));
const History = React.lazy(() => import("../pages/views/pets/history"));

const Calendar = React.lazy(() => import("../pages/views/appointment/calendar"));
const AppointmentList = React.lazy(() => import("../pages/views/appointment/list"));
const AddAppointment = React.lazy(() => import("../pages/views/appointment/add"));

const Settings = React.lazy(() => import("../pages/views/settings/"));
const Profile = React.lazy(() => import("../pages/views/profile/"));

const Owners = React.lazy(() => import("../pages/views/owners/list"));
const AddOwner = React.lazy(() => import("../pages/views/owners/add"));

const Vaccines = React.lazy(() => import("../pages/views/miscellaneous/vaccines/"));
const Dewormers = React.lazy(() => import("../pages/views/miscellaneous/dewormers/"));
const Exams = React.lazy(() => import("../pages/views/miscellaneous/exams/"));

export const publicRoutes = [
  { key: "auth", path: '/auth', component: Login, },
  { key: "forgotpassword", path: '/forgotpassword', component: ForgotPassword, },
  { key: "signup", path: '/signup', component: SignUp, },
];

export const privateRoutes = [
  { key: "dashboard", path: '/dashboard', component: Dashboard, },
  { key: "addpet", path: '/pets/add', component: AddPet, },
  { key: "editpets", path: '/pets/edit', component: AddPet, },
  { key: "listpets", path: '/pets/list', component: ListPets, },
  { key: "history", path: '/history', component: History, },
  { key: "calendar", path: '/calendar', component: Calendar, },
  { key: "appointmentlist", path: '/appointment/list', component: AppointmentList, },
  { key: "addappointment", path: '/appointment/add', component: AddAppointment, },
  { key: "settings", path: '/settings', component: Settings, },
  { key: "profile", path: '/profile', component: Profile, },
  { key: "editprofile", path: '/edit-profile', component: Profile, },
  { key: "owners", path: '/owners/list', component: Owners, },
  { key: "addowner", path: '/owners/add', component: AddOwner, },
  { key: "editowner", path: '/owners/edit', component: AddOwner, },
  { key: "vaccines", path: '/vaccines', component: Vaccines, },
  { key: "dewormers", path: '/dewormers', component: Dewormers, },
  { key: "exams", path: '/exams', component: Exams, },

];