import React, { useState, useEffect } from 'react';
import { Alert as AntAlert, Button } from 'antd';
import Breadcrumb from '../breadcrumb';
import Input from '../input';
import MotionAlert from '../motionAlert';
import { useApp } from '../../../context/AppContext';
import { motion } from "framer-motion"

const Card = ({ children, title, breadcrumb, show, message, typeAlert, isModal, withoutBackground, idMenu, errorState, avatarState, showAdd, addText, handleAdd, ...props }) => {
    const { setActiveClassName } = useApp();

    useEffect(() => {
        setActiveClassName(idMenu);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idMenu]);

    const [showMotionAlert, setShowMotionAlert] = useState(true);
    useEffect(() => {
        setShowMotionAlert(show);
        setTimeout(() => {
            setShowMotionAlert(false);
        }, 5000);
    }, [show]);

    if (isModal) {
        return (
            <>
                {title &&
                    <div className="col-12">
                        <div className="form-heading">
                            <h4>{title}</h4>
                        </div>
                    </div>
                }
                {showMotionAlert && <MotionAlert show={showMotionAlert} message={message} type={typeAlert} />}
                {children}
            </>
        );
    }

    return (
        <div className="row">
            <div className="d-flex justify-content-between align-items-center">
                {!props.inputProps ? <Breadcrumb items={breadcrumb} showAdd={showAdd} addText={addText} handleAdd={handleAdd} /> :
                    <div className="col-8 col-sm-8 col-md-6 col-xl-4">
                        <Input
                            type='text'
                            style={{ marginBottom: 10 }}
                            {...props.inputProps}
                        />
                    </div>
                }
                {showAdd &&
                    <div className="col-sm-2 text-end">
                        <Button
                            title={addText}
                            onClick={handleAdd}
                            // icon={<FeatherIcon icon="plus-circle" />}
                            type="primary"
                        >
                            Agregar
                        </Button>
                    </div>
                }
            </div>


            {errorState?.show &&
                <div className="doctor-submit text-start">
                    <motion.div
                        initial={{ opacity: 0, marginBottom: 0 }}
                        animate={{
                            opacity: errorState?.show ? 1 : 0,
                            marginBottom: errorState?.show ? 20 : 0
                        }}>
                        <AntAlert type="error" showIcon message={errorState?.error}></AntAlert>
                    </motion.div>
                </div>
            }
            <div className="col-sm-12">
                <div className={withoutBackground ? '' : 'card'}>
                    <div className="card-body">
                        {title &&
                            <div className="col-12">
                                {/* <div className="form-heading"> */}
                                <span >{title}</span>
                                {/* </div> */}
                            </div>
                        }
                        {showMotionAlert && <MotionAlert show={showMotionAlert} message={message} type={typeAlert} />}
                        {children}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Card;