import axios from "axios";
import { API_ENDPOINT } from "./config";

const client = axios.create({
    baseURL: API_ENDPOINT,
    timeout: 60000,
    headers: { "Content-Type": "application/json" },
});

// Función para manejar los errores
const handleError = (error) => {
    return Promise.reject(error);
};

client.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    } else {
        // Si no hay token, se redirige al login 
    }

    return config;
}, handleError);

client.interceptors.response.use(function (response) {
    // Cualquier código de estado que este dentro del rango de 2xx causa la ejecución de esta función 
    // Haz algo con los datos de la respuesta
    return response;
}, function (error) {
    if (error?.response?.status === 401) { // Si el token es inválido, se redirige al login
        // Si el token expiró, se redirige al login
        console.log('Token expirado')
        localStorage.clear(); // Limpia el localStorage
        window.location.reload(); // Recarga la página
    }
    // Cualquier código de estado que este fuera del rango de 2xx causa la ejecución de esta función
    // Haz algo con el error
    return Promise.reject(error);
});

export default client;