import { Link } from 'react-router-dom';
import FeatherIcon from "feather-icons-react";
import { Button } from 'antd';

const Breadcrumb = ({ items = [], showAdd, addText, handleAdd }) => {
    return (
        <div className="page-header">
            <div className="row align-items-center">
                <div className="col-sm-10">
                    <ul className="breadcrumb">
                        {items.map((item, index) => (
                            <li key={index} className={`breadcrumb-item ${index === items.length - 1 ? 'active' : ''}`}>
                                {item.path && index !== items.length - 1 ? (
                                    <Link to={item.path}>{item.name}</Link>
                                ) : (
                                    item.name
                                )}
                                {index < items.length - 1 && (
                                    <i className="feather-chevron-right">
                                        <FeatherIcon icon="chevron-right" />
                                    </i>
                                )}
                            </li>
                        ))}

                    </ul>
                </div>
                {/* {showAdd &&
                    <div className="col-sm-2 text-end">
                        <Button
                            title={addText}
                            onClick={handleAdd}
                            // icon={<FeatherIcon icon="plus-circle" />}
                            type="primary"
                        >
                            Agregar
                        </Button>
                    </div>
                } */}
            </div>
        </div>
    );
};

export default Breadcrumb;