import React, { useEffect } from 'react';
import { Tooltip } from 'antd';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { Form } from 'antd';
const customStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1);',
        boxShadow: state.isFocused ? '0 0 0 1px #3D6039' : 'none',
        '&:hover': {
            borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
        },
        borderRadius: '10px',
        fontSize: "14px",
        minHeight: "45px",
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
        transition: '250ms',
        width: '35px',
        height: '35px',
    }),
};


const CustomSelect = ({ name, rules, label, required, selectProps, add, addText, handleAdd, ...props }) => {
    return (
        <div style={{ position: 'relative' }}>
            <div className=" local-forms">
                <label>{label} {required && <span className="login-danger"> *</span>}</label>

            </div>
            {add &&
                <div className="local-forms-add" onClick={selectProps.isDisabled ? null : handleAdd} >
                    <Tooltip title={selectProps.isDisabled ? null : addText}>
                        <i className="fa fa-plus"></i>
                    </Tooltip>
                </div>}

            <Form.Item
                name={name}
                rules={required ? rules : []}
                className="form-group local-forms"
                {...props}
            >
                {!selectProps.isClearable ?
                    <Select
                        placeholder=""
                        menuPortalTarget={document.body}
                        id="search-commodity"
                        components={{
                            IndicatorSeparator: () => null
                        }}

                        styles={customStyles}
                        {...selectProps}
                    /> :
                    <CreatableSelect
                        placeholder=""
                        menuPortalTarget={document.body}
                        id="search-commodity"
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        styles={customStyles}
                        {...selectProps}
                    />
                }
            </Form.Item>
        </div>

    );
}

export default CustomSelect;