import React, { useState } from 'react';
import { Form, Col, Upload, Modal, message } from 'antd';
import { CameraOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import styles from './styles';
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const UploadComponent = ({ rules, name, valuePropName, getValueFromEvent, fileList, onChangePicture, disabled, action, setErrorState, setDisbledbutton, ...props }) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewOpen(true);
        setPreviewImage(file.url || file.preview);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const beforeUpload = (file) => {
        if (!file) {
            console.error('No se proporcionó un archivo');
            setErrorState({ error: 'No se proporcionó un archivo', show: true });
            setDisbledbutton(true);
            return false;
        };

        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            setDisbledbutton(true);
            setErrorState({ error: 'Solo puedes subir archivos JPG/PNG', show: true });
            message.error('Solo puedes subir archivos JPG/PNG');
            return false;
        };

        const maxSize = 299968; // 2MB
        if (file.size > maxSize) {
            setDisbledbutton(true);
            setErrorState({ error: 'La imagen debe ser más pequeña que 2MB,\n para continuar elimina la imagen.', show: true });
            message.error('La imagen debe ser más pequeña que 2MB,\n para continuar elimina la imagen.', 5);
            return false;
        }
        setDisbledbutton(false);
        setErrorState({ error: '', show: false });
        return isJpgOrPng && maxSize;
    };
    const handleCancel = () => setPreviewOpen(false);


    return (
        <Col >
            <Form.Item
                name={name}
                valuePropName={valuePropName}
                rules={rules}
                getValueFromEvent={getValueFromEvent}
                // className={'profile-img-wrap'}
                {...props}
            >
                <ImgCrop rotationSlider>
                    <Upload
                        data='image/png'
                        listType="picture-card"
                        fileList={fileList}
                        beforeUpload={beforeUpload}
                        onChange={onChangePicture}
                        onPreview={handlePreview}
                        disabled={disabled}
                        status='done'
                        action={action}
                    >
                        {fileList?.length < 1 && '+ Subir'}
                    </Upload>
                    <div className='cam_profile'>
                        <CameraOutlined style={{ color: 'white', textAlign: 'center' }} />
                    </div>
                </ImgCrop>
            </Form.Item>

            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
        </Col>
    );

}

export default UploadComponent