import React from 'react';
import { Table } from 'antd';
import { itemRender, onShowSizeChange } from './components/Pagination';

const CustomTable = ({ ...props }) => {
    return (
        <div className="table-responsive doctor-list">
            <Table
                pagination={{
                    total: props?.datasourse?.length,
                    showTotal: (total, range) =>
                        `Mostrando ${range[0]} a ${range[1]} de ${total} entradas`,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    pageSize: 20,
                    onShowSizeChange: onShowSizeChange,
                    itemRender: itemRender,
                }}

                {...props}
            />
        </div>
    );
};

export default CustomTable;
