import React from 'react';
import { motion } from 'framer-motion';
import { Alert } from 'antd'; // Asumiendo que Alert viene de 'antd', ajustar según sea necesario

const MotionAlert = ({ show, message, type }) => {
    return (
        <motion.div
            initial={{ opacity: 0, marginBottom: 0 }}
            animate={{
                opacity: show ? 1 : 0,
                marginBottom: show ? 20 : 0,
                shake: show ? { x: 20, y: 10 } : 0,
            }}>
            <Alert type={type} showIcon message={message} />
        </motion.div>
    );
};

export default MotionAlert;