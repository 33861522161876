import React, { createContext, useContext, useState } from 'react';
import { Breadcrumb } from '../components/ui/';
const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [breadcrumb, setBreadcrumb] = useState([]);
    const [activeClassName, setActiveClassName] = useState('defaultClass');

    const updateBreadcrumb = (items) => {
        setBreadcrumb(items);
    };

    return (
        <AppContext.Provider value={{ updateBreadcrumb, setActiveClassName, activeClassName }}>
            <Breadcrumb items={breadcrumb} />
            {children}
        </AppContext.Provider>
    );
};

export const useApp = () => useContext(AppContext);