import {
    THEME_COLOR,
    GRAY_SCALE,
    BORDER,
    BODY_BACKGROUND,
    FORM
} from '../constants/ThemeConstant';

export const baseTheme = {
    colorPrimary: THEME_COLOR.PRIMARY,
    colorSecondary: THEME_COLOR.SECONDARY,
    backgroundPrimary: THEME_COLOR.BACKGROUND,
    colorBgContainer: THEME_COLOR.GRAY_DARK,
    colorText: GRAY_SCALE.GRAY_LIGHT,
    colorLink: THEME_COLOR.PRIMARY,
    colorBorder: BORDER.BASE_COLOR,
    controlHeight: FORM.INPUT_HEIGHT,
    fontFamily: 'Relaway, sans-serif',
    fontSizeBase: 14,
    fontSizeHeading2: 22,
    fontSizeHeading4: 17,
    borderRadius: FORM.BORDER_RADIUS,
    itemActiveBg: THEME_COLOR.SECONDARY,
};

const getBaseComponentThemeConfig = (
    {
        Button = {
            fontWeight: 700,
            primaryShadow: '0 0 0 rgba(0, 0, 0, 0)',
            paddingInline: 30,
        },
        Menu = {
            itemBg: 'transparent',
            itemSelectedBg: GRAY_SCALE.WHITE,
            subMenuItemBg: THEME_COLOR.SECONDARY,

            // estilos de los items del menu
            itemColor: THEME_COLOR.PRIMARY,
            itemSelectedColor: THEME_COLOR.PRIMARY,
            itemHoverBg: GRAY_SCALE.WHITE,
            itemHoverColor: THEME_COLOR.PRIMARY,
            // estilos de las barras del menu
            activeBarHeight: 2,
            activeBarWidth: 700,
            colorActiveBar: THEME_COLOR.SECONDARY,
            colorActiveBarHover: THEME_COLOR.PRIMARY,
            colorActiveBarSelected: THEME_COLOR.SECONDARY,
            colorActiveBarSelectedHover: THEME_COLOR.SECONDARY,
            // groupTitleLineHeight: 100,

            // horizontalItemSelectedColor: THEME_COLOR.SECONDARY,
            // horizontalItemHoverColor: THEME_COLOR.SECONDARY,

        },
        Card = {

        },
        Table = {},
        Select = {},
        DatePicker = {},
        Layout = {},
        Input = {
            // activeBg: THEME_COLOR.PRIMARY,
        },
        Dropdown = {},
        Calendar = {},
        Modal = {},
        Form = {
            labelColor: THEME_COLOR.PRIMARY,
            labelSize: 14,
            itemMarginBottom: 12,
            verticalLabelPadding: 0,
        }
    } = {}
) => {
    return {
        Button: {
            ...Button
        },
        Dropdown: {
            // controlPaddingHorizontal: 20,
            controlHeight: 37,
            borderRadiusLG: 10,
            colorText: THEME_COLOR.PRIMARY,
            fontWeight: 700,
            // paddingXXS: '4px 0px',
            ...Dropdown
        },
        Calendar: {
            ...Calendar
        },
        Checkbox: {
            lineWidth: 2,
            borderRadiusSM: 4
        },
        Card: {

            ...Card
        },
        Layout: {
            bodyBg: BODY_BACKGROUND,
            headerBg: GRAY_SCALE.WHITE,
            ...Layout
        },
        Breadcrumb: {
            colorTextDescription: GRAY_SCALE.GRAY_LIGHT,
            colorText: baseTheme.colorPrimary,
            colorBgTextHover: 'transparent'
        },
        Menu: {


            ...Menu
        },
        Input: {
            ...Input
        },
        Pagination: {
            paginationItemSize: 30,
            borderRadius: '50%',
            colorBgContainer: baseTheme.colorPrimary,
            colorPrimary: GRAY_SCALE.WHITE,
            colorPrimaryHover: GRAY_SCALE.WHITE,
        },
        Steps: {
            wireframe: true,
            controlHeight: 32,
            waitIconColor: GRAY_SCALE.GRAY_LIGHT
        },
        DatePicker: {
            controlHeight: 40,
            controlHeightSM: 26,
            borderRadiusSM: 6,
            lineWidthBold: 0,
            ...DatePicker
        },
        Radio: {
            fontSizeLG: 18
        },
        Slider: {
            // colorPrimaryBorder: rgba(baseTheme.colorPrimary, 0.8),
            colorPrimaryBorderHover: baseTheme.colorPrimary
        },
        Select: {
            paddingXXS: '4px 0px',
            controlHeight: 40,
            controlHeightSM: 30,
            // controlItemBgActive: rgba(baseTheme.colorPrimary, 0.1),
            ...Select
        },
        TreeSelect: {
            controlHeightSM: 24
        },
        Tooltip: {
            // colorBgDefault: rgba(GRAY_SCALE.DARK, 0.75),
            controlHeight: 32
        },
        Timeline: {
            lineType: 'dashed'
        },
        Tag: {
            lineHeight: 2.1
        },
        Table: {
            colorText: GRAY_SCALE.GRAY,
            colorBgContainer: GRAY_SCALE.WHITE,
            ...Table
        },
        Modal: {
            ...Modal
        },
        Form: {
            fontWeight: 700,
            fontSize: 12,
            ...Form
        }
    }
}

export const lightTheme = {
    token: baseTheme,
    component: getBaseComponentThemeConfig()
}

