import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { publicRoutes, privateRoutes } from "../configs/RoutesConfig";
import PublicRoutes from "./PublicRoutes";
import AppRoute from "./AppRoute";
import PrivateRoutes from "./PrivateRoutes";
import { AUTHENTICATED_ENTRY } from "../configs/AppConfig";

export default function Routers() {
  return (
    <Routes>
      <Route path="/" element={<PrivateRoutes />}>
        <Route path="/" element={<Navigate to={AUTHENTICATED_ENTRY} />} />
        {privateRoutes.map((route, index) => {
          return (
            <Route
              key={route.key}
              path={route.path}
              element={
                <AppRoute routeKey={route.key} component={route.component} />
              }
            />
          );
        })}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
      <Route path="/" element={<PublicRoutes />}>
        {publicRoutes.map((route, index) => {
          return (
            <Route
              key={route.key}
              path={route.path}
              element={
                <AppRoute routeKey={route.key} component={route.component} />
              }
            />
          );
        })}
      </Route>
    </Routes>
  );
}
