import React, { useEffect, createContext, useContext, useState } from 'react';
import Alert from '../components/Alert'; // Asegúrate de ajustar la ruta de importación según sea necesario

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
    const [alert, setAlert] = useState({ visible: false, message: '', title: '' });
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (showError) {
            setTimeout(() => {
                setShowError(false);
            }, 2000);
        }
    }, [showError]);
    const showAlert = (alertData) => setAlert({ visible: true, ...alertData });

    const hideAlert = () => setAlert({ visible: false, message: '', title: '' });

    return (
        <AlertContext.Provider value={{ alert, showAlert, hideAlert, showError, error, setShowError, setError }}>
            {children}
            {alert.visible && (
                <Alert
                    open={alert.visible}
                    type={alert.type}
                    title={alert.title}
                    message={alert.message}
                    onConfirm={() => { hideAlert(); alert.onConfirm() }}
                    onClose={hideAlert}
                    imageSrc={alert.imageSrc}
                    single={alert.single}
                    okText={alert.okText}
                    cancelText={alert.cancelText}
                />
            )}
        </AlertContext.Provider>
    );
};

export const useAlert = () => useContext(AlertContext);