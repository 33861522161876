import React from 'react';

const Button = ({ title, type, extraClass, onClick, disabled, loading }) => {
    return (
        <button
            type={type}
            onClick={onClick}
            className={`btn  ${extraClass} `}
            disabled={disabled || loading}
        >
            {title}
        </button>
    );
};

export default Button;