import React, { useState } from 'react';
import { Eye, EyeOff } from "feather-icons-react/build/IconComponents";
import { DatePicker, TimePicker, Form, Input, Checkbox } from 'antd'; // Paso 1: Importar DatePicker
const CustomInput = ({ name, rules, value, required, type, placeholder, label, onChange, disabled, extraclass, format, rows, propsDatePicker, propsTimePicker, ...props }) => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible);

    const renderLabel = () => (
        label && (
            <div className={type !== 'checkbox' ? "local-forms" : ""}>
                <label >
                    {label}
                    {required && <span className="login-danger"> *</span>}
                </label>
            </div>
        )
    );

    const renderToggleIcon = () => (

        <span className="toggle-password" onClick={disabled ? null : togglePasswordVisibility}>
            {passwordVisible ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
        </span>

    );

    // const renderTogglePassword = () => (
    //     <span className="toggle-password" onClick={disabled ? null : togglePasswordVisibility}>
    //         {passwordVisible ? <EyeOff className="react-feather-custom" /> : <Eye className="react-feather-custom" />}
    //     </span>
    // );

    const renderInput = () => {
        switch (type) {
            case 'text':
            case 'email':
                const inputType = type === 'password' && !passwordVisible ? 'password' : type;
                return <Input
                    className="form-control"
                    type={inputType}
                    placeholder={placeholder}
                    onChange={onChange}
                    disabled={disabled}
                />;
            case 'number':
                return <Input
                    className="form-control"
                    type={type}
                    placeholder=''
                    onChange={onChange}
                    disabled={disabled}
                />;
            case 'date':
                return <DatePicker
                    placeholder={''}
                    disabled={disabled}
                    className="form-control datetimepicker"
                    suffixIcon={null}
                    {...propsDatePicker}
                />;
            case 'time':
                return <TimePicker.RangePicker
                    format={'HH:mm a'}
                    className="form-control d-flex"
                    {...propsTimePicker}
                />
            case 'textarea':
                return <Input.TextArea
                    className="form-control"
                    rows={rows}
                    onChange={onChange}
                    disabled={disabled}
                />;
            case 'password':
                return (
                    <Input
                        className="form-control"
                        type={!passwordVisible ? 'password' : 'text'}
                        placeholder=''
                        onChange={onChange}
                        disabled={disabled}
                    />
                );
            case 'checkbox':
                return (
                    <Checkbox
                        checked={value}
                        onChange={onChange}
                        disabled={disabled}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div style={{ position: 'relative' }}>
            {renderLabel()}
            <Form.Item name={name} rules={required ? rules : []} className={type !== 'checkbox' ? `form-group local-forms ${extraclass}` : ''} validateTrigger="onBlur" {...props}>
                {renderInput()}
            </Form.Item>
            {/* {type === 'password' && renderTogglePassword()} */}
            {type === 'password' && renderToggleIcon()}

        </div>
    );
};

export default CustomInput;