
export const ROW_GUTTER = 16;
export const SIDE_NAV_WIDTH = 250;
export const SIDE_NAV_COLLAPSED_WIDTH = 80;
export const HEADER_HEIGHT = 60;
export const FOOTER_HEIGHT = 30;
export const TOP_NAV_HEIGHT = 55;
export const SIDE_NAV_LIGHT = 'SIDE_NAV_LIGHT';
export const SIDE_NAV_DARK = 'SIDE_NAV_DARK';
export const NAV_TYPE_SIDE = 'SIDE';
export const NAV_TYPE_TOP = 'TOP';
export const DIR_LTR = 'ltr';
export const DIR_RTL = 'rtl';
export const CONTENT_MAX_WIDTH = 1400;
export const CONTENT_HEIGHT_OFFSET = HEADER_HEIGHT + FOOTER_HEIGHT;
export const HEADER_BG_DEFAULT_COLOR_LIGHT = '#ffffff';
export const HEADER_BG_DEFAULT_COLOR_DARK = '#283142';
export const LAYOUT_CONTENT_GUTTER = 20;
export const LAYOUT_CONTENT_GUTTER_SM = 15;
export const LAYOUT_MIN_HEIGHT = '100vh';
export const SIDE_NAV_DARK_BG_COLOR = '#001529';
export const LOGO_HEIGHT = 40;
// export const CONTENT_HEIGHT = `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)`;
export const CONTENT_HEIGHT = `calc(100vh - 124px)`;
export const CONTENT_HEIGHT_MOBILE = `calc(100vh - 190px)`;
export const CONTENT_HEIGHT_COLLAPSED = `calc(100vh - 104px)`;

export const PRIMARY_COLOR = '#3D6039';
export const SECONDARY_COLOR = '#FF7F01';
export const BACKGROUND_PRIMARY_COLOR = '#F2F4F7';

export const WHITE = '#ffffff';
export const DARK = '#000000';
export const GRAY_DARK = '#f2f4f7';
export const GRAY = '#455560';
export const GRAY_LIGHT = '#808080';
export const GRAY_LIGHTER = '#ededed';
export const GRAY_LIGHTEST = '#f7f7f8';
export const RED = '#ff0000';
export const GREEN = '#3e6f3e';

export const LOGO = '/logo-edumet.png';
export const LOGO192 = '/logo192.png';


export const BODY_BACKGROUND = '#f5f5f5';

export const FONT_SIZE_BASE = 14;


export const FORM = {
    GAP: 10,
    BORDER_RADIUS: 20,
    INPUT_HEIGHT: 35,
    INPUT_WIDTH_AUTH: 270,
    INPUT_WIDTH: '100%',
    INPUT_FILTER: '100%',
}

export const TEMPLATE = {
    HEADER_HEIGHT,
    FOOTER_HEIGHT,
    TOP_NAV_HEIGHT,
    CONTENT_HEIGHT_OFFSET,
    SIDE_NAV_WIDTH,
    SIDE_NAV_COLLAPSED_WIDTH,
    SIDE_NAV_DARK_BG_COLOR,
    CONTENT_MAX_WIDTH,
    HEADER_BG_DEFAULT_COLOR_LIGHT,
    HEADER_BG_DEFAULT_COLOR_DARK,
    LAYOUT_CONTENT_GUTTER,
    LAYOUT_CONTENT_GUTTER_SM,
    NAV_TYPE_SIDE,
    NAV_TYPE_TOP,
    SIDE_NAV_LIGHT,
    SIDE_NAV_DARK,
    DIR_LTR,
    DIR_RTL,
    LAYOUT_MIN_HEIGHT,
    LOGO_HEIGHT,
    CONTENT_HEIGHT_COLLAPSED
}

export const THEME_COLOR = {
    WHITE,
    PRIMARY: PRIMARY_COLOR,
    SECONDARY: SECONDARY_COLOR,
    BACKGROUND: BACKGROUND_PRIMARY_COLOR,
    GRAY_LIGHT,
    GRAY_DARK,
    DANGER: RED,
    SUCCESS: GREEN,
    BODY_BACKGROUND,
    SHADOW_PRIMARY: '0 0 10px rgba(5, 0, 78, 0.4)',
    SHADOW_SECONDARY: '0 0 10px rgba(255, 127, 1, 0.4)',
}

export const FONT_WEIGHT = {
    LIGHTER: 'lighter',
    LIGHT: 300,
    NORMAL: 400,
    SEMIBOLD: 500,
    BOLD: 'bold',
    BASE: 400,
    BOLDER: 'bolder'
}

export const FONT_SIZES = {
    BASE: FONT_SIZE_BASE,
    LG: FONT_SIZE_BASE + 2,
    SM: 12
}

export const SPACER = {
    0: '0',
    1: '0.25rem',
    2: '0.5rem',
    3: '1rem',
    4: '1.5rem',
    5: '3rem',
}

export const COL = {
    XS: { flex: '100%', },
    SM: { flex: '45%', },
    MD: { flex: '45%', },
    LG: { flex: '45%', },
    XL: { flex: '45%', },
}

export const COL_FILTER = {
    XS: { flex: '45%', },
    SM: { flex: '16%', },
    MD: { flex: '16%', },
    LG: { flex: '16%', },
    XL: { flex: '16%', },
}

export const COL_FILTER_ACTION = {
    XS: { flex: '2%', },
    SM: { flex: '2%', },
    MD: { flex: '2%', },
    LG: { flex: '2%', },
    XL: { flex: '2%', },
}

export const COL_FILTER_HEADER = {
    XS: { flex: '50%', },
    SM: { flex: '50%', },
    MD: { flex: '50%', },
    LG: { flex: '50%', },
    XL: { flex: '50%', },
}

const BREAKPOINT = {
    xs: '480px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1400px',
    xxl: '1600px'
}

export const MEDIA_QUERIES = {
    DESKTOP: `(min-width: ${BREAKPOINT.xl})`,
    LAPTOP: `(max-width: ${BREAKPOINT.xxl})`,
    LAPTOP_ABOVE: `(min-width: ${BREAKPOINT.xxl})`,
    TABLET: `(max-width: ${BREAKPOINT.lg})`,
    MOBILE: `(max-width: ${BREAKPOINT.xs})`,
}

export const BORDER = {
    BASE_COLOR: '#e6ebf1'
}

export const GRAY_SCALE = {
    DARK,
    WHITE,
    GRAY_DARK,
    GRAY,
    GRAY_LIGHT,
    GRAY_LIGHTER,
    GRAY_LIGHTEST
}

export const DARK_MODE = {
    TEXT_COLOR: '#b4bed2',
    HEADING_COLOR: '#d6d7dc',
    BG_COLOR: '#283142',
    BORDER_BASE_COLOR: '#4d5b75',
    HOVER_BG_COLOR: '#364663',
    ACTIVE_BG_COLOR: '#293956',
    DROP_DOWN_SHADOW: '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 58%), 0 9px 28px 8px rgb(0 0 0 / 15%)'
}

export const THEME_LAYOUT = {
    LAYOUT: {
        // position: 'relative',
        minHeight: LAYOUT_MIN_HEIGHT,
        overflow: 'auto',
        backgroundColor: BODY_BACKGROUND,

    },
    CONTENT: {
        height: LAYOUT_MIN_HEIGHT,
        padding: MEDIA_QUERIES.MOBILE ? 10 : LAYOUT_CONTENT_GUTTER,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    HEADER: {
        width: '100%',
        minWidth: 0,
        height: HEADER_HEIGHT,
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        borderRadius: 30,
        justifyContent: 'space-between',
        backgroundColor: WHITE,
        padding: '0 10px',
    },
    CONTENT_APP: {
        height: CONTENT_HEIGHT,
        backgroundColor: WHITE,
        borderRadius: 8,
        padding: 10,
        marginTop: 10,
    },
    FOOTER: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        textAlign: 'center',
        fontSize: 12,
        paddingTop: 10,
        paddingBottom: 10,
    },
}

