import { ConfigProvider } from "antd";
import React from "react";
import Layouts from "./layouts";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import { AlertProvider } from './context/AlertContext';
import { AppProvider } from "./context/AppContext";
import { ConfigAppProvider } from './context/ConfigAppContext';
import { DIR_LTR } from "./constants/ThemeConstant";
import { lightTheme } from "./configs/ThemeConfig";
import esES from "antd/lib/locale/es_ES";

const locales = esES;
const direction = DIR_LTR;
const themeConfig = { ...lightTheme };
const App = () => (

  <ConfigProvider
    theme={{
      token: themeConfig.token,
      components: themeConfig.component
    }}
    locale={locales}
    direction={direction}
  >
    <BrowserRouter basename={document.querySelector("base")?.getAttribute("href") ?? "/"}>
      <ConfigAppProvider>
        <AuthProvider>
          <AlertProvider>
            <AppProvider>
              <Layouts />
            </AppProvider>
          </AlertProvider>
        </AuthProvider>
      </ConfigAppProvider>
    </BrowserRouter>
    <div className="sidebar-overlay"></div>
  </ConfigProvider>
);

export default App;
