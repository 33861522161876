import React, { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { UNAUTHENTICATED_ENTRY } from "../configs/AppConfig";
import { AuthContext } from "../context/AuthContext";

const PrivateRoutes = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();

  if (!isAuthenticated) {
    return (
      <Navigate
        to={`${UNAUTHENTICATED_ENTRY}?ReturnUrl=${location.pathname}`}
        replace
      />
    );
  }

  return <Outlet />;
};

export default PrivateRoutes;
