export const AUTH = Object.freeze({
    INVALID_EMAIL: 'El correo electrónico no es válido',
    VERIFY_EMAIL_ERROR: 'Error al verificar el correo. Por favor, inténtalo de nuevo.',
    USER_NOT_FOUND: 'Usuario no encontrado',
    PASSWORD_INCORRECT: 'La contraseña es incorrecta',
    ACCOUNT_LOCKED: 'La cuenta está bloqueada. Contacta al soporte.',
    TOKEN_EXPIRED: 'El token ha expirado. Por favor, inicia sesión de nuevo.',
    UNAUTHORIZED_ACCESS: 'Acceso no autorizado',
    SERVER_ERROR: 'Error del servidor. Por favor, inténtalo más tarde.',
    SUCCESSFUL_LOGIN: 'Inicio de sesión exitoso',
    SUCCESSFUL_LOGOUT: 'Cierre de sesión exitoso',
    ACCOUNT_CREATED: 'Cuenta creada exitosamente',
    PASSWORD_RESET: 'Contraseña restablecida exitosamente',
    EMAIL_SENT: 'Correo enviado exitosamente',
    EMAIL_PASSWORD_INCORRECT: 'El correo electrónico o la contraseña son incorrectos',
    ERROR_ACCOUNT_CREATED: 'Error al crear la cuenta',
    ERROR_SEND_EMAIL: 'Error al verificar el correo. Por favor, inténtalo de nuevo.',
});

export const PETS = Object.freeze({
    ERROR_GET_PETS: 'Error al obtener las mascotas',
});

