import client from "./client";

export const getAccountsService = async () => {
    return client.get(`account/`).catch(error => {
        console.error('Error making get owners request:', error.message);
        throw error; // Re-lanza el error para manejarlo más arriba en la cadena de promesas
    });
};

export const createAccountService = async (data) => {
    return client.post(`account/`, data).catch(error => {
        console.error('Error making post owners request:', error.message);
        throw error; // Re-lanza el error para manejarlo más arriba en la cadena de promesas
    });
};

export const updateAccountService = async (data) => {
    return client.post(`account/edit`, data).catch(error => {
        console.error('Error making put owners request:', error.message);
        throw error; // Re-lanza el error para manejarlo más arriba en la cadena de promesas
    });
};

export const getSettingsService = async () => {
    return client.get(`settings/`).catch(error => {
        console.error('Error making get settings request:', error.message);
        throw error; // Re-lanza el error para manejarlo más arriba en la cadena de promesas
    });
};

export const updateSettingsService = async (data) => {
    return client.post(`settings/update/`, data).catch(error => {
        console.error('Error making put settings request:', error.message);
        throw error; // Re-lanza el error para manejarlo más arriba en la cadena de promesas
    });
};


