import React, { createContext, useState, useEffect, useContext } from 'react';
import dayjs from "dayjs";
import { WEEK_DAYS } from "../constants";
import { getSettings } from "../modules/accounts";
import { getAppointmentsStatus } from "../modules/appointments";
import { message } from 'antd';
// 1. Crear el contexto de autenticación
export const AuthContext = createContext();

// 2. Crear el proveedor de contexto
export const AuthProvider = ({ children }) => {

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState({});
    const [title, setTitle] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const [settings, setSettings] = useState({});

    useEffect(() => {
        const token = localStorage.getItem('token');
        const user = JSON.parse(localStorage.getItem('user'));
        if (token) {
            loadSettings();
            setUser(user);
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
            setUser(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setIsAuthenticated(false);
        setUser(null);
    };

    const updateUser = (user) => {
        setUser(user);
        localStorage.setItem('user', JSON.stringify(user));
    };

    const loadSettings = async () => {
        console.log("Loading settings")
        try {
            const response = await getSettings();
            const resSettings = response?.data;
            if (response.success) {
                const days = resSettings?.daysOpen;
                const timeOpenDay = resSettings?.dateOpen;
                const timeCloseDay = resSettings?.dateClose;
                const daysOpen = days.split('-').map((day, index) => (day === 'S' ? WEEK_DAYS[index] : null)).filter(Boolean);
                const dateOpen = dayjs(timeOpenDay, 'HH:mm')
                const dateClose = dayjs(timeCloseDay, 'HH:mm')
                setSettings({ ...response?.data, daysOpen, dateOpen, dateClose, timeCloseDay, timeOpenDay });
                console.log("Settings loaded")
                loadStatus();
            } else {
                message.error('Error loading settings');
                logout();
            }
        } catch (error) {
            console.error('Error fetching settings:', error);
            logout();
        };

    };

    const loadStatus = async () => {
        console.log("Loading status")
        try {
            const response = await getAppointmentsStatus();
            if (response) {
                const status = [];
                for (const item of response) {
                    status.push({
                        key: parseInt(item.appoinmentStatusId),
                        label: item.name,
                        color: item.color,
                    });
                }
                setSettings(eventPrev => ({ ...eventPrev, status: { select: status, options: response } }));
                console.log("Status loaded")
            } else {
                message.error('Error loading settings');
                logout();
            }
        } catch (error) {
            console.error('Error fetching status:', error);
            logout();
        };

    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, user, updateUser, title, setTitle, isVerified, setIsVerified, settings, loadSettings }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);