import client from "./client";

export const getAppointmentsService = async () => {
    return client.get(`appointment`).catch(error => {
        console.error('Error making appointments request:', error.message);
        throw error; // Re-lanza el error para manejarlo más arriba en la cadena de promesas
    });
}
export const createAppointmentService = async (data) => {
    return client.post(`appointment/`, data).catch(error => {
        console.error('Error making create appointment request:', error.message);
        throw error; // Re-lanza el error para manejarlo más arriba en la cadena de promesas
    });
}
export const editAppointmentService = async (data) => {
    return client.put(`appointment/${data.appoimentId}/`, data).catch(error => {
        console.error('Error making edit appointment request:', error.message);
        throw error; // Re-lanza el error para manejarlo más arriba en la cadena de promesas
    });
}

export const getAppointmentsByIdService = async (data) => {
    console.log("🚀 ~ getAppointmentsByIdService ~ data:", data)
    return client.get(`appointment/${data.appoimentId}/`).catch(error => {
        console.error('Error making appointments by id request:', error.message);
        throw error; // Re-lanza el error para manejarlo más arriba en la cadena de promesas
    });
};

export const addExamAppointmentService = async (data) => {
    return client.post(`appointment/exams/`, data).catch(error => {
        console.error('Error making add exam appointment request:', error.message);
        throw error; // Re-lanza el error para manejarlo más arriba en la cadena de promesas
    });
}
export const addVaccineAppointmentService = async (data) => {
    return client.post(`appointment/vaccines/`, data).catch(error => {
        console.error('Error making add vaccine appointment request:', error.message);
        throw error; // Re-lanza el error para manejarlo más arriba en la cadena de promesas
    });
}
export const addDewormingAppointmentService = async (data) => {
    return client.post(`appointment/dewormers/`, data).catch(error => {
        console.error('Error making add deworming appointment request:', error.message);
        throw error; // Re-lanza el error para manejarlo más arriba en la cadena de promesas
    });
}
export const getAppointmentsStatusService = async () => {
    return client.get(`appointmentStatus/`).catch(error => {
        console.error('Error making appointments status request:', error.message);
        throw error; // Re-lanza el error para manejarlo más arriba en la cadena de promesas
    });
}
