import React from 'react';
import { Modal, Button } from 'antd';
import { warningicon } from "../../assets/imagepath";
const AlertComponent = ({ title, open, message, messageOptional, onConfirm, onClose, type, loading, single, okText, cancelText, ...props }) => {

    // const extraclass = type === 'alert-warning' ? 'btn-danger text-white' : '';
    const img = type === 'warning' ? warningicon : null;
    return (
        <Modal
            title={null}
            open={open}
            onCancel={onClose}
            footer={null}
            centered
            {...props}
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body text-center">

                        <img src={img} alt="#" width={50} height={46} />
                        <h3>{title}</h3>
                        <p>{message}</p>
                        {messageOptional && <span className="text-danger">{messageOptional}</span>}
                        <div className="m-t-20">
                            <Button
                                className=" me-2"
                                loading={loading}
                                type='primary'
                                onClick={onConfirm}>
                                {single?.textButton || okText || 'Aceptar'}
                            </Button>
                            {!single?.show && <Button
                                className="btn-se btn-white"
                                onClick={onClose}
                                loading={loading}
                            >
                                {cancelText || 'Cancelar'}

                            </Button>}

                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default AlertComponent;