import React, { memo, Suspense, lazy, useContext } from "react";
import Pages from "../pages";
import LoadingComponent from "../components/Loading";
import { AuthContext } from "../context/AuthContext";
const AuthLayout = lazy(() => import("./AuthLayout"));
const AppLayout = lazy(() => import("./AppLayout"));

const Layouts = () => {
  const { isAuthenticated } = useContext(AuthContext);
  const Layout = isAuthenticated ? AppLayout : AuthLayout;

  return (
    <Suspense fallback={<LoadingComponent />}>
      <Layout>
        <Pages />
      </Layout>
    </Suspense>
  );
};

export default memo(Layouts);
