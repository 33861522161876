
import React, { useState } from "react";
import { Button, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const CustomDropdown = ({ data, status, record, calendar, onChangeStatus, loading }) => {
    const [title, setTitle] = useState(record?.name);
    const [color, setColor] = useState(record?.color);


    const handleMenuClick = async (e) => {
        const key = e.key;
        const options = status?.options;
        const select = status?.select;
        const selectedItem = select.find(item => item.key == key);
        const appoinmentStatus = options.find(item => item.appoinmentStatusId == key);
        const appoimentId = data?.appoimentId;
        setColor(selectedItem.color);
        setTitle(selectedItem.label);
        onChangeStatus({ appoimentId, appoinmentStatus, data: { ...data, appoinmentStatus } });
    };
    const menuProps = {
        items: status.select,
        onClick: handleMenuClick,
    };

    return (
        <Dropdown menu={menuProps}>
            <Button
                className={calendar ? null : 'custom-dropdown'}
                style={{ color: '#FFF', backgroundColor: `${color}`, borderRadius: calendar ? 2 : 20, padding: '0 10', minWidth: calendar ? 0 : 160, fontWeight: 500, height: calendar ? 20 : 32, paddingLeft: calendar ? 5 : 10, paddingRight: calendar ? 5 : 10, borderColor: `${color}20`, marginBottom: calendar ? 5 : 0 }}
                loading={loading}
                disabled={loading}
            >
                {title}
                <DownOutlined />
            </Button>
        </Dropdown>
    );
};

export default CustomDropdown;