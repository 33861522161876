

const Badge = ({ title, color }) => {
    return (
        <button
            className="custom-badge"
            data-bs-toggle="tooltip"
            style={{
                backgroundColor: `${color}60`,
                // color: backgroundColor,
                fontSize: '13px',
                fontWeight: '300',
            }}
        >
            {title}
        </button>
    );
};

export default Badge;