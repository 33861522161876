import {
    getAccountsService,
    createAccountService,
    updateAccountService,
    getSettingsService,
    updateSettingsService
} from '../services/account';

const getAccounts = async () => {
    try {
        const response = await getAccountsService();
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
const createAccount = async (data) => {
    try {
        const response = await createAccountService(data);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

const updateAccount = async (data) => {
    try {
        const response = await updateAccountService(data);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

const getSettings = async () => {
    try {
        const response = await getSettingsService();
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

const updateSettings = async (data) => {
    try {
        const response = await updateSettingsService(data);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};





export {
    getAccounts,
    createAccount,
    updateAccount,
    getSettings,
    updateSettings
};