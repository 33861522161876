import React from "react";

const AppRoute = ({
  component: Component,
  routeKey,
  blankLayout,
  ...props
}) => {
  return <Component {...props} />;
};

export default AppRoute;
