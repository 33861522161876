// src/context/AppDataContext.jsx
import React, { createContext, useContext, useState, useEffect } from 'react';
// import Auth from '../modules/auth'

const ConfigAppContext = createContext();

export const ConfigAppProvider = ({ children }) => {
    const [appName, setAppName] = useState('');
    const [logo, setLogo] = useState('');
    const [favicon, setFavicon] = useState('');
    const [isApiOnline, setIsApiOnline] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    // Cargar datos iniciales
    useEffect(() => {
        // Aquí puedes cargar el nombre, logo y favicon desde una fuente externa o configuración
        setAppName("Nombre de la App");
        setLogo("/path/to/logo.png");
        setFavicon("/path/to/favicon.ico");

        // Verificar conexión con la API
        const checkApiConnection = async () => {

            try {
                // Reemplaza 'API_ENDPOINT' con el endpoint real de tu API para verificar su estado
                // const response = await Auth.status();
                // // console.log("🚀 ~ checkApiConnection ~ response:", response)
                // if (response.ok) {
                setIsApiOnline(true);
                setIsLoading(false);
                // } else {
                //     setIsApiOnline(false);
                //     setIsLoading(false);
                // }
            } catch (error) {
                console.error("Error al verificar la conexión con la API:", error);
                setIsApiOnline(false);
            }
        };

        checkApiConnection();
    }, []);

    return (
        <ConfigAppContext.Provider value={{ appName, logo, favicon, isApiOnline, isLoading }}>
            {children}
        </ConfigAppContext.Provider>
    );
};

export const useAppData = () => useContext(ConfigAppContext);